<template>
  <addEditRepairAddressView ref="addEditRepairAddressView" />
  <addFileView ref="addFileView" />
  <el-main>
    <div class="teacher">
      <div class="app-table">
        <div class="app-body">
          <div>
            <el-form :inline="true" class="demo-form-inline" v-model="form">
              <el-form-item label="地点名称">
                <el-input
                  placeholder="请输入地点名称"
                  v-model="form.name"
                ></el-input>
              </el-form-item>
              <el-form-item label="地点简称">
                <el-input
                  placeholder="请输入地点简称"
                  v-model="form.subName"
                ></el-input>
              </el-form-item>

              <!-- <el-form-item label="公司名称">
                <el-input
                  placeholder="请输入公司名称"
                  v-model="form.companyName"
                ></el-input>
              </el-form-item> -->
              <el-form-item>
                <el-button
                  type="primary"
                  @click="onSubmit"
                  @keyup.enter="searchEnter"
                  >搜索</el-button
                >
                <el-button type="success" @click="add">添加</el-button>
                <!-- <el-button type="warning" @click="exportExcel">导出报表</el-button> -->
                <!-- <el-button type="danger" @click="importExcel">批量导入<el-icon class="el-icon--right"><Upload /></el-icon></el-button> -->
              </el-form-item>
            </el-form>
          </div>
          <el-table
            size="small"
            :data="list"
            style="width: 100%"
            border
            :row-class-name="tableRowClassName"
          >
            <el-table-column prop="id" label="id" />
            <el-table-column prop="name" label="地点名称" />
            <el-table-column prop="subName" label="地点简称" />
            <el-table-column prop="code" label="地点编码" />
            <!-- <el-table-column prop="companyCode" label="公司编码" /> -->
            <!-- <el-table-column prop="companyName" label="公司名称" /> -->
            <!-- <el-table-column prop="parentCode" label="父节点编码"/> -->
            <el-table-column prop="parentName" label="上级地点名称" />
            <el-table-column prop="nodeLevel" label="节点级别" />
            <el-table-column prop="sortNum" label="排序" />
            <!-- <el-table-column prop="isEffect" label="是否可用" /> -->
            <el-table-column prop="createTime" label="创建时间" :formatter="dateFormat"/>
            <el-table-column label="操作" width="140px">
              <template style="display: block" #default="scope">
                <el-button
                  size="small"
                  type="success"
                  @click="editRepairAddress(scope.row)"
                  >编辑
                </el-button>
                <el-button
                  size="small"
                  type="danger"
                  @click="deleteRepairAddress(scope.row.id)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
            <template v-slot:empty>
              <el-empty description="暂无数据"></el-empty>
            </template>
          </el-table>
          <el-config-provider :locale="locale">
            <el-pagination
              :total="total"
              :page-size="limit"
              :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="changePageSize"
              @current-change="changeCurrentPage"
            >
            </el-pagination>
          </el-config-provider>
        </div>
      </div>
    </div>
  </el-main>
</template>
<script>
import addEditRepairAddressView from "@/components/addEditRepairAddress.vue";
import addFileView from "@/components/addExcelFile.vue";
import { ElConfigProvider, ElMessageBox, ElMessage } from "element-plus";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import { httpService } from "@/utils/httpService";
import { Picture as IconPicture, Upload } from "@element-plus/icons-vue";

export default {
  name: "RepairAddressListView",
  data() {
    return {
      type: 0,
      title: "",
      page: 0,
      pageSize: 10,
      total: 0,
      limit: 10,
      list: [],
      form: {
        name: "",
        subName: "",
        code: "",
        companyCode: "",
        companyName: "",
        parentCode: "",
        parentName: "",
      },
    };
  },
  components: {
    addEditRepairAddressView,
    addFileView,
    IconPicture,
    ElConfigProvider,
    ElMessageBox,
    ElMessage,
    Upload,
  },
  setup() {
    return {
      locale: zhCn,
    };
  },
  mounted() {
    this.getRepairAddressList();
  },
  watch: {
    $route() {
      this.getRepairAddressList();
    },
  },
  methods: {
    searchEnter: function (e) {
      // 使用 which 和 keyCode 属性来解决兼容问题
      var keyCode = window.event ? e.keyCode : e.which;
      var val = e.target.value;
      if (keyCode == 13 && val) {
        this.getRepairAddressList();
      }
    },
    add() {
      this.$refs.addEditRepairAddressView.title = "添加报修地点表";
      this.$refs.addEditRepairAddressView.personnelType = this.type;
      this.$refs.addEditRepairAddressView.dialogFormVisible = true;
    },
    editRepairAddress(obj) {
      this.$refs.addEditRepairAddressView.title = "修改信息";
      this.$refs.addEditRepairAddressView.getRepairAddressById(obj.id);
      this.$refs.addEditRepairAddressView.dialogFormVisible = true;
    },
    deleteRepairAddress(id) {
      ElMessageBox.confirm("确定要删除本条记录吗？", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          httpService("/api/repairAddress/deleteById", "id=" + id, "post").then(
            (data) => {
              if (data.code == 200) {
                ElMessage.success("删除成功");
                this.getRepairAddressList();
              } else {
                ElMessage.error("删除失败，请重试！");
              }
            }
          );
        })
        .catch(() => {});
    },
    reloadData() {
      this.page = 1;
      this.getRepairAddressList();
    },
    importExcel() {
      this.$refs.addFileView.dialogFormVisible = true;
      this.$refs.addFileView.title = "批量导入报修地点表";
      this.$refs.addFileView.uploadApiUrl = "/api/repairAddress/importExcel";
    },
    exportExcel() {
      httpService(
        "/api/repairAddress/exportExcel",
        {
          name: this.form.name,
          subName: this.form.subName,
          code: this.form.code,
          companyCode: this.form.companyCode,
          companyName: this.form.companyName,
          parentCode: this.form.parentCode,
          parentName: this.form.parentName,
        },
        "get"
      ).then((data) => {
        if (data.code == 200) {
          window.open(data.msg, "_blank");
        }
      });
    },
    onSubmit() {
      this.getRepairAddressList();
    },
    // 每页记录数改变，size：回调参数，表示当前选中的“每页条数”
    changePageSize(size) {
      this.pageSize = size;
      this.limit = size;
      this.getRepairAddressList();
    },

    // 改变页码，page：回调参数，表示当前选中的“页码”
    changeCurrentPage(page) {
      this.page = page;
      this.getRepairAddressList();
    },
    tableRowClassName({ row, rowIndex }) {
      return "";
    },
    getRepairAddressList() {
      httpService(
        "/api/repairAddress/list/" + this.page + "/" + this.pageSize,
        {
          name: this.form.name,
          subName: this.form.subName,
          code: this.form.code,
          companyCode: this.form.companyCode,
          companyName: this.form.companyName,
          parentCode: this.form.parentCode,
          parentName: this.form.parentName,
        },
        "get"
      ).then((data) => {
        this.list = data.list;
        this.total = data.total;
        this.pageCount = data.pages;
      });
    },
  },
};
</script>
<style scoped>
.el-main {
  padding: 0px;
}

.teacher-content {
  width: 100%;
}

.app-table {
  background: #eff3f5;
  padding: 10px;
}

.app-search {
  padding-top: 20px;
  border-top: 1px solid #dedede;
  margin-top: 20px;
}

.app-separator {
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
}

.app-body {
  background: white;
  padding: 20px 20px 10px 20px;
  margin-bottom: 10px;
}

.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-lighter);
}

.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-lighter);
}

.el-pagination {
  text-align: center;
  margin-top: 10px;
}
</style>

