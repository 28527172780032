<template>
  <el-dialog v-model="dialogFormVisible" :title="title" @close="closeDialog">
    <el-form
      :model="addRepairAddressForm"
      ref="addRepairAddressRef"
      :rules="addRepairAddressrules"
    >
        <el-form-item
        label="上级地点名称"
        :label-width="formLabelWidth"
        prop="parentName"
      >
        <el-input
          v-model="addRepairAddressForm.parentName"
          autocomplete="off"
          ref="name"
          placeholder="请填写上级地点名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="地点名称" :label-width="formLabelWidth" prop="name">
        <el-input
          v-model="addRepairAddressForm.name"
          autocomplete="off"
          ref="name"
          placeholder="请填写地点名称"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="地点简称"
        :label-width="formLabelWidth"
        prop="subName"
      >
        <el-input
          v-model="addRepairAddressForm.subName"
          autocomplete="off"
          ref="name"
          placeholder="请填写地点简称"
        ></el-input>
      </el-form-item>
      <el-form-item label="地点编码" :label-width="formLabelWidth" prop="code">
        <el-input
          v-model="addRepairAddressForm.code"
          autocomplete="off"
          ref="name"
          placeholder="请填写地点编码"
        ></el-input>
      </el-form-item>
         <el-form-item
        label="节点级别"
        :label-width="formLabelWidth"
        prop="nodeLevel"
      >
        <el-input
          v-model="addRepairAddressForm.nodeLevel"
          autocomplete="off"
          type="number"
          placeholder="请填写节点级别"
        ></el-input>
      </el-form-item>
      <el-form-item label="排序" :label-width="formLabelWidth" prop="sortNum">
        <el-input
          v-model="addRepairAddressForm.sortNum"
          autocomplete="off"
          type="number"
          placeholder="请填写排序"
        ></el-input>
      </el-form-item>
   
  
        <!-- <el-form-item label="创建时间" :label-width="formLabelWidth"
                                      prop="createTime" v-if="addRepairAddressForm.id">
                            <el-input
                                    v-model="addRepairAddressForm.createTime"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写创建时间"
                            ></el-input>
                        </el-form-item> -->
        <!-- <el-form-item label="公司编码" :label-width="formLabelWidth"
                                      prop="companyCode">
                            <el-input
                                    v-model="addRepairAddressForm.companyCode"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写公司编码"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="公司名称" :label-width="formLabelWidth"
                                      prop="companyName">
                            <el-input
                                    v-model="addRepairAddressForm.companyName"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写公司名称"
                            ></el-input>
                        </el-form-item> -->
        <!-- <el-form-item
          label="是否可用"
          :label-width="formLabelWidth"
          prop="isEffect"
          v-if="addRepairAddressForm.id"
        >
          <el-input
            v-model="addRepairAddressForm.isEffect"
            autocomplete="off"
            ref="name"
            placeholder="请填写是否可用"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="父节点编码" :label-width="formLabelWidth"
                                      prop="parentCode">
                            <el-input
                                    v-model="addRepairAddressForm.parentCode"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写父节点编码"
                            ></el-input>
                        </el-form-item> -->
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel()">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref } from "vue";
import { httpService } from "@/utils/httpService";
import { ElMessage, ElForm } from "element-plus";
import { vxRule } from "@/utils/validator";

export default {
  name: "AddRepairAddress",
  components: { ElForm, ElMessage },
  data() {
    return {
      dialogFormVisible: ref(false),
      formLabelWidth: "120px",
      title: "",
      personnelType: "",
      addRepairAddressForm: {
        name: "",
        subName: "",
        code: "",
        sortNum: "",
        createTime: "",
        companyCode: "",
        companyName: "",
        isEffect: "",
        parentCode: "",
        nodeLevel: "",
        parentName: "",
      },
      // 校验规则
      addRepairAddressrules: {
        name: vxRule(true, null, "blur"),
        subName: vxRule(true, null, "blur"),
        code: vxRule(true, null, "blur"),
        sortNum: vxRule(true, null, "blur"),
        nodeLevel: vxRule(true, null, "blur"),
      },
    };
  },
  methods: {
    closeDialog() {
      this.clear();
    },
    cancel() {
      this.dialogFormVisible = false;
      this.clear();
    },
    getRepairAddressById(id) {
      httpService("/api/repairAddress/queryById", { id: id }, "get").then(
        (data) => {
          this.addRepairAddressForm = data;
        }
      );
    },
    clear() {
      this.dialogFormVisible = false;
      //清空form
      this.addRepairAddressForm.id = "";
      this.addRepairAddressForm.name = "";
      this.addRepairAddressForm.subName = "";
      this.addRepairAddressForm.code = "";
      this.addRepairAddressForm.sortNum = "";
      this.addRepairAddressForm.createTime = "";
      this.addRepairAddressForm.companyCode = "";
      this.addRepairAddressForm.companyName = "";
      this.addRepairAddressForm.isEffect = "";
      this.addRepairAddressForm.parentCode = "";
      this.addRepairAddressForm.nodeLevel = "";
      this.addRepairAddressForm.parentName = "";
    },
    save() {
      this.$refs.addRepairAddressRef.validate((valid) => {
        if (valid) {
          let params = new FormData(); //创建一个form对象,必须是form对象否则后端接受不到数据
          params.append("name", this.addRepairAddressForm.name);
          params.append("subName", this.addRepairAddressForm.subName);
          params.append("code", this.addRepairAddressForm.code);
          params.append("sortNum", this.addRepairAddressForm.sortNum);
          // params.append("createTime", this.addRepairAddressForm.createTime);
          params.append("companyCode", this.addRepairAddressForm.companyCode);
          params.append("companyName", this.addRepairAddressForm.companyName);
          // params.append("isEffect", this.addRepairAddressForm.isEffect);
          params.append("parentCode", this.addRepairAddressForm.parentCode);
          params.append("nodeLevel", this.addRepairAddressForm.nodeLevel);
          params.append("parentName", this.addRepairAddressForm.parentName);
          if (this.addRepairAddressForm.id) {
            params.append("id", this.addRepairAddressForm.id);
            httpService("/api/repairAddress/edit", params, "post").then(
              (data) => {
                if (data.code == 200) {
                  ElMessage.success("修改成功");
                  this.clear();
                  //刷新父组件的数据
                  this.$parent.getRepairAddressList();
                } else {
                  ElMessage.error("修改失败");
                }
              }
            );
          } else {
            httpService("/api/repairAddress/add", params, "post").then(
              (data) => {
                if (data.code == 200) {
                  ElMessage.success("添加成功");
                  this.clear();
                  //刷新父组件的数据
                  this.$parent.getRepairAddressList();
                } else {
                  ElMessage.error("添加失败");
                }
              }
            );
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style>
</style>